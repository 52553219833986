.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title {
  margin: 0;
  font-size: 100px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.description {
  margin: 0;
  font-size: 18px;
  max-width: 800px;
  line-height: 1.5em;
}

.button {
  width: auto;
  padding: 20px 40px;
  background: white;
  color: black;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  margin-top: 30px;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-position: center;
  background-image: url("https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
